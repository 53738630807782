<template>
  <div class="main main--internal" v-if="status === 1">
    <h1 class="title">Оплата успешна!</h1>
    <h2 class="title__subtitle">Скачивание начнется автоматически, или нажмите на ссылку если оно не началось</h2>
    <h2 class="title__subtitle center__subtitle">При необходимости, для корректного открытия документов на смартфоне, Вам необходимо установить приложение "Word" и любой архиватор для распаковывания файлов (Zip и другие)</h2>
    <a :href="claim.archive_zip" class="title__subtitle" style="margin: 0">Скачать</a>
    <div class="sharereview">
      <h3 class="sharereview__title">Поделитесь отзывом о наc в сервисах и мы Вам вернем 50 руб. за каждый отзыв.</h3>
      <ul class="sharereview__list">
        <li>⭐ Яндекс карты - <a href="https://yandex.ru/profile/129952909810">https://yandex.ru/profile/129952909810</a></li>
        <li>⭐ Гугл - <a href="https://g.page/r/CbToHEJDIW54EAg/review">https://g.page/r/CbToHEJDIW54EAg/review</a></li>
      </ul>
      <h4 class="sharereview__title">После оставления отзыва, напишите об этом в Ватцап на +79882727273 и мы перечислим Вам деньги.</h4>
    </div>
  </div>
  <div class="main main--internal" v-else-if="status === 2">
    <h1 class="title">Заявка ожидает подтверждения оплаты</h1>
    <h2 class="title__subtitle">Страница будет обновляться каждые {{ time }} секунд.</h2>
    <h2 class="title__subtitle">Если у Вас возникла проблема, обратитесь в тех. поддержку</h2>
    <a :href="yooMoneyUrl" class="button button--fill">Перейти к оплате</a>
  </div>
  <main class="main" v-else-if="status === 3">
    <section class="main__about">
      <h1>Страница не найдена</h1>
    </section>
  </main>
</template>

<script>
export default {
  name: 'ConfirmedPay',
  async asyncData ({ store, route }) {
    await store.dispatch('GET_DIVORCE_CLAIM', route.params.id)
  },
  data () {
    return {
      time: 10,
      interval: null
    }
  },
  computed: {
    status () {
      return this.$store.state.confirmed_claim.code
    },
    yooMoneyUrl () {
      return this.$store.state.confirmed_claim.url ? this.$store.state.confirmed_claim.url : null
    },
    claim () {
      return this.$store.state.confirmed_claim.claim ? this.$store.state.confirmed_claim.claim : null
    }
  },
  mounted () {
    if (this.status === 1 && this.claim && this.claim.archive_zip) {
      const link = document.createElement('a')
      link.setAttribute('href', this.claim.archive_zip)
      link.setAttribute('download', this.claim.archive_zip)
      link.click()
      ym(85084831,'reachGoal','download_docs')
      gtag('event', 'downloading', {
        'event_category': 'archives',
        'event_label': 'archive_download'
        });
    }
    if (this.status === 2) {
      this.interval = setInterval(() => {
        location.reload()
      }, this.time * 1000)
    }
  },
  beforeDestroy () {
    if (this.status === 2 && this.interval) {
      clearInterval(this.interval)
    }
  },
  metaInfo () {
    const status = this.$store.state.confirmed_claim.status
    if (status === 1) {
      return this.$seo(
        'common',
        'Оплата успешна!',
        null,
        null,
        null,
        null,
        null
      )
    } else if (status === 2) {
      return this.$seo(
        'common',
        'Заявка ожидает подтверждения оплаты',
        null,
        null,
        null,
        null,
        null
      )
    } else if (status === 3) {
      return this.$seo(
        'common',
        'Страница не найдена',
        null,
        null,
        'Страница не найдена',
        null,
        null
      )
    }
  }
}
</script>
